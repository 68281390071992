interface IProps {
  element: HTMLDivElement | null;
  step: number;
  rightArrow: HTMLDivElement | null;
  leftArrow: HTMLDivElement | null;
}

export const updateArrows = (
  element: HTMLDivElement | null,
  rightArrow: HTMLDivElement | null,
  leftArrow: HTMLDivElement | null,
  delay: number = 0,
  step: number = 0,
) => {
  setTimeout(() => {
    const scrollLeft = element?.scrollLeft ?? 0;
    const offsetWidth = element?.offsetWidth ?? 0;
    const scrollWidth = element?.scrollWidth ?? 0;

    if (scrollLeft + step <= 0) {
      leftArrow?.classList.remove("active");
    } else if (scrollLeft + step > 0) {
      leftArrow?.classList.add("active");
    }

    if (scrollLeft + offsetWidth >= scrollWidth - 100) {
      rightArrow?.classList.remove("active");
      element?.classList.add("active");
    } else if (scrollLeft + offsetWidth - 100 <= scrollWidth) {
      element?.classList.remove("active");
      rightArrow?.classList.add("active");
    }
  }, delay);
};

export const handleHorizontalScroll = ({
  element,
  step,
  rightArrow,
  leftArrow,
}: IProps) => {
  if (element) {
    element.scrollTo({
      left: element.scrollLeft + step,
      behavior: "smooth",
    });

    updateArrows(element, rightArrow, leftArrow, 100, step);
  }
};
