import { format, formatRelative } from "date-fns";

import { DropdownItem } from "@/components/form/Dropdown/Dropdown.types";
import { VolunteerTrainingDay } from "@/types/forumTypes";

export function parseDateRelative(date: Date) {
  if (!date) return;
  return formatRelative(date, Date.now());
}

export function parseDateWithTime(date: string) {
  if (!date) return;
  return format(new Date(date), "dd-MM-yyy hh:mm:ss");
}

export function parseDate(date: Date) {
  if (!date) return;
  return format(date, "dd-MM-yyy");
}

export const formatVolunteerTrainingDaysList = (
  volunteerTrainingDays: VolunteerTrainingDay[],
): DropdownItem[] => {
  return volunteerTrainingDays.map((trainingDay) => {
    const dateObj = new Date(trainingDay.date);
    const formattedDate = `${dateObj.toLocaleDateString("nl-NL", {
      weekday: "long",
      day: "numeric",
      month: "long",
    })} om ${dateObj.toLocaleTimeString("nl-NL", {
      hour: "2-digit",
      minute: "2-digit",
    })} uur`;

    return {
      name: `${trainingDay.trainingTitle} op ${formattedDate}`,
      value: trainingDay.id,
    };
  });
};

export const getDropdownItemById = (
  volunteerTrainingDateId: string,
  volunteerTrainingDays: VolunteerTrainingDay[],
): DropdownItem | undefined => {
  // Format the list of volunteer training days
  const formattedList = formatVolunteerTrainingDaysList(volunteerTrainingDays);

  // Find the item with the matching ID
  return formattedList.find((item) => item.value === volunteerTrainingDateId);
};
